<template>
  <div class="container" v-if="valid">
    <div class="row justify-content-center">
      <div class="col-12 mb-5">
        <app-section :title="$t('Cambiar contraseña')"></app-section>
      </div>
      <div class="col-10 col-sm-6">
        <ResetPasswordForm :token="token">
        </ResetPasswordForm>
      </div>
    </div>

  </div>
</template>

<script>

import ResetPasswordForm from "@/components/forms/ResetPasswordForm";
import AppSection from "@/components/AppSection";
import {mapGetters} from "vuex";
export default {
  name: "ContactUs",
  components: {
    ResetPasswordForm,
    AppSection
  },
  computed:{
    ...mapGetters(["cineClubSubscribed"])
  },
  props:{
    token: {
      default(){
        return null
      },
      type: String
    }
  },
  mounted: function() {
    if (!this.token){
      this.$router.push({name: "club"});
    }else {
      this.validateToken();
    }
  },
  data: function(){
    return {
      valid: false
    }
  },
  methods: {
    validateToken(){
      this.$store.dispatch("validateResetToken", {email: this.$route.query.email, token: this.token}).then(()=>{
        this.valid = true;
      }).catch(()=>{
        this.valid = false;
        this.$router.push({name: "club"});
      })

    }
  },
  watch: {
    cineClubSubscribed(subscribed){
      if (subscribed){
        this.$store.dispatch("fetchClubHome")
        setTimeout(()=>{
          this.$router.push({name: "club"});
        }, 5000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>